export function buildParams(page, size, field, sort, onlyRelevantBookings, organizationId, isAdmin) {
    let params = new URLSearchParams()

    params.append('page', page);
    params.append('size', size);
    if (field && sort) {
        params.append('sort', `${field},${sort}`);
    }
    if (onlyRelevantBookings) {
        params.append('showOnlyRelevantBookings', 'true');
    }
    if (organizationId && isAdmin) {
        params.append('organizationId', organizationId);
    }
    return params;
}

export function buildParamsMultipleSorts(page, size, sorts, filters, onlyRelevantBookings, organizationId, isAdmin) {
    let params = new URLSearchParams();

    params.append('page', page);
    if (size) {
        params.append('size', size);
    }
    for (const {field, direction} of sorts) {
        params.append('sort', `${field},${direction}`);
    }
    for (const {field, value} of filters) {
        params.append(field, value);
    }
    if (onlyRelevantBookings) {
        params.append('showOnlyRelevantBookings', 'true');
    }
    if (organizationId && isAdmin) {
        params.append('organizationId', organizationId);
    }
    return params;
}